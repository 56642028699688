const pluginLogin = [
  {
    path: '/auth',
    name: 'plugin-login',
    component: () => import('@/views/pluginLogin/PluginLogin.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/google',
    name: 'plugin-login-auth-google',
    component: () => import('@/views/pluginLogin/auth/google/AuthGoogle.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/qr',
    name: 'plugin-login-auth-qr',
    component: () => import('@/views/pluginLogin/auth/qr/AuthQr.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/finish/:id/:authtype',
    name: 'plugin-login-finish',
    component: () => import('@/views/pluginLogin/auth/finish/AuthFinish.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

export default pluginLogin
