import { mdiAccountGroup, mdiAccountSchoolOutline, mdiHumanMaleBoard } from '@mdi/js'

export default [
  {
    subheader: 'GEBRUIKERS  ',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    title: 'Klassen',
    icon: mdiAccountGroup,
    to: 'users-class-list',
    resource: 'Pupils',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    title: 'Leerlingen',
    icon: mdiAccountSchoolOutline,
    to: 'users-pupils-list',
    resource: 'Pupils',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    title: 'Leerkrachten',
    icon: mdiHumanMaleBoard,
    to: 'users-teachers-list',
    resource: 'Teachers',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
]
