const loginDetails = [
  //
  //* ——— LoginDetails ——————————————————
  //

  {
    path: '/login-details/pupils/list',
    name: 'login-details-pupils-list',
    component: () => import('@/views/loginDetails/LoginDetailsPupils.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/login-details/teachers/list',
    name: 'login-details-teachers-list',
    component: () => import('@/views/loginDetails/LoginDetailsTeachers.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/account-settings',
    name: 'account-settings',
    component: () => import('@/views/accountSettings/AccountSettings.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default loginDetails
