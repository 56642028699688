const printRoutes = [
  {
    path: '/print/qr',
    name: 'print-qr',
    component: () => import('@/views/print/qr.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/print/label',
    name: 'print-label',
    component: () => import('@/views/print/labels.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/card-advance/CardAdvance.vue'),
    meta: {
      layout: 'content',
      role: 'admin',
    },
  },
]

export default printRoutes
