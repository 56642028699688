export const canNavigate = to => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const isLoggedIn = localStorage.getItem('userData') !== null

  return to.matched.some(route => {
    const doesRouteRequireLogin = route.meta.role ? route.meta.role === 'user' : false
    const doesRouteRequireGlobalManager = route.meta.role ? route.meta.role === 'globalManager' : false

    // If no login required, allow routing
    if (!doesRouteRequireLogin && !doesRouteRequireGlobalManager) return true

    // If login required
    if (doesRouteRequireLogin || doesRouteRequireGlobalManager) {
      if (!isLoggedIn) {
        return false
      }

      if (doesRouteRequireGlobalManager && !userData.isGlobalManager) {
        return false
      }

      return true
    }

    return true
  })
}

export const _ = undefined
