import {
  mdiAccountGroup,
  mdiAccountSchoolOutline,
  mdiFileArrowUpDownOutline,
  mdiFormTextboxPassword,
  mdiHumanMaleBoard,
} from '@mdi/js'

/* eslint-disable */
// prettier-ignore
export default [
  // dashboard
  {
    title: 'Klassen',
    icon: mdiAccountGroup,
    to: 'users-class-list',
    resource: 'Pupils',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    title: 'Leerlingen',
    icon: mdiAccountSchoolOutline,
    to: 'users-pupils-list',
    resource: 'Pupils',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder', 'Leerkracht'],
  },
  {
    title: 'Leerkrachten',
    icon: mdiHumanMaleBoard,
    to: 'users-teachers-list',
    resource: 'Teachers',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    title: 'Inloggegevens',
    icon: mdiFormTextboxPassword,
    to: 'login-details-pupils-list',
    resource: 'LoginDetails',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    title: 'Importeren',
    icon: mdiFileArrowUpDownOutline,
    to: 'import',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },

  { header: 'Leerlingen' },
  { avatar: require('@/assets/images/avatars/6.png'), title: 'Rena Brant', email: 'nephrod@preany.co.uk', time: '21/05/2019' },
]
