<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userData.avatar"
            :src="userData.avatar"
          ></v-img>
          <v-icon
            v-else
            color="primary"
            size="28"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="ms-3 pb-3 pt-2 text--primary font-weight-semibold mb-n1">
        {{ school.name }}
      </div>
      <!-- Email -->
      <v-list-item
        class="mb-5"
        :to="{ name: 'school-picker' }"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiSwapHorizontal }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>School veranderen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item :to="{ name: 'apps-user-view', params: { id: 21 } }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profiel</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Email -->
      <a
        href="mailto:jonas@blokpass.be"
        target="_blank"
        style="text-decoration:auto"
      >
        <v-list-item>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiChatQuestionOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Vraag stellen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </a>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Uitloggen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { initialAbility } from '@/plugins/acl/config'
import { useRouter } from '@core/utils'
import {
  mdiAccountOutline, mdiChatOutline, mdiChatQuestionOutline, mdiCheckboxMarkedOutline, mdiCogOutline,
  mdiCurrencyUsd, mdiHelpCircleOutline,
  mdiLogoutVariant, mdiSwapHorizontal,
} from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))

    const school = JSON.parse(localStorage.getItem('school'))

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('school')
      localStorage.removeItem('exp')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login-logged-out' })
    }

    return {
      logoutUser,
      userData,
      school,

      icons: {
        mdiAccountOutline,
        mdiChatQuestionOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiSwapHorizontal,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
