import administration from './administration'

// import chartsAndMaps from './charts-and-maps'
// import formsAndTables from './forms-and-tables'
import login from './login'

// import others from './others'
// import uiElements from './ui-elements'
import users from './users'

import importObj from './import'

// Array of sections
export default [
  ...users,
  ...importObj,
  ...login,
  ...administration,

  // ...uiElements,
  // ...formsAndTables,
  // ...chartsAndMaps,
  // ...others,
]
