const users = [
  //
  //* ——— Classes ——————————————————
  //

  {
    path: '/users/class/list',
    name: 'users-class-list',
    component: () => import('@/views/users/classes/class-list/ClassList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/users/class/view/:id',
    name: 'users-class-view',
    component: () => import('@/views/users/classes/class-view/ClassView.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Pupils ——————————————————
  //

  {
    path: '/users/pupils/list',
    name: 'users-pupils-list',
    component: () => import('@/views/users/pupils/user-list/UserList.vue'),
    meta: {
      layout: 'content',
      resource: 'Pupils',
    },
  },
  {
    path: '/users/pupils/view/:id',
    name: 'users-pupils-view',
    component: () => import('@/views/users/pupils/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      resource: 'Pupils',
    },
  },

  //
  //* ——— Teachers ——————————————————
  //

  {
    path: '/users/teachers/list',
    name: 'users-teachers-list',
    component: () => import('@/views/users/teachers/user-list/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/users/teachers/view/:id',
    name: 'users-teachers-view',
    component: () => import('@/views/users/teachers/user-view/UserView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default users
