const administration = [
  //
  //* ——— Classes ——————————————————
  //

  {
    path: '/administration/school/list',
    name: 'administration-school-list',
    component: () => import('@/views/administration/schools/school-list/SchoolList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/administration/school/view/:id',
    name: 'administration-school-view',
    component: () => import('@/views/administration/schools/school-view/SchoolView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default administration
