import { mdiFileDocumentOutline, mdiOfficeBuildingCogOutline } from '@mdi/js'

export default [
  {
    subheader: 'ADMINISTRATIE  ',
    allowedRoles: ['Globale beheerder'],
  },
  {
    title: 'Scholen',
    icon: mdiOfficeBuildingCogOutline,
    to: 'administration-school-list',
    resource: 'GlobalManager',
    action: 'read',
    allowedRoles: ['Globale beheerder'],
  },
  {
    title: 'Factuur',
    icon: mdiFileDocumentOutline,
    children: [
      {
        title: 'Overzicht',
        to: 'apps-invoice-list',
      },
      {
        title: 'Preview',
        to: { name: 'apps-invoice-preview', params: { id: 4987 } },
      },
      {
        title: 'Edit',
        to: { name: 'apps-invoice-edit', params: { id: 4987 } },
      },
      {
        title: 'Add',
        to: { name: 'apps-invoice-add' },
      },
    ],
    allowedRoles: ['Globale beheerder'],
  },
]
