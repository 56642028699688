import {
  mdiAccountGroup,
  mdiAccountSchoolOutline,
  mdiFileArrowUpDownOutline,
  mdiFormTextboxPassword,
  mdiHumanMaleBoard,
} from '@mdi/js'

export default [
  {
    subheader: 'KLASSEN  ',
    allowedRoles: ['Schooladministrator', 'Globale beheerder', 'Leerkracht'],
  },
  {
    title: 'Klassen',
    icon: mdiAccountGroup,
    to: 'users-class-list',
    resource: 'Pupils',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    subheader: 'LEERLINGEN  ',
    allowedRoles: ['Schooladministrator', 'Globale beheerder', 'Leerkracht'],
  },
  {
    title: 'Leerlingen',
    icon: mdiAccountSchoolOutline,
    to: 'users-pupils-list',
    resource: 'Pupils',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder', 'Leerkracht'],
  },
  {
    title: 'Inloggegevens ',
    icon: mdiFormTextboxPassword,
    to: 'login-details-pupils-list',
    resource: 'LoginDetailsPupils',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    title: 'Gegevens importeren',
    icon: mdiFileArrowUpDownOutline,
    to: 'import-pupil',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    subheader: 'LEERKRACHTEN  ',
    allowedRoles: ['Schooladministrator', 'Globale beheerder', 'Leerkracht'],
  },
  {
    title: 'Leerkrachten',
    icon: mdiHumanMaleBoard,
    to: 'users-teachers-list',
    resource: 'Teachers',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
]
