import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","left":"","nudge-bottom":"14","min-width":"230","content-class":"user-profile-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"ms-4",attrs:{"bottom":"","color":"success","overlap":"","offset-x":"12","offset-y":"12","dot":""}},[_c(VAvatar,_vm._g(_vm._b({staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},'v-avatar',attrs,false),on),[(_vm.userData.avatar)?_c(VImg,{attrs:{"src":_vm.userData.avatar}}):_c(VIcon,{attrs:{"color":"primary","size":"28"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")])],1)],1)]}}])},[_c(VList,[_c('div',{staticClass:"ms-3 pb-3 pt-2 text--primary font-weight-semibold mb-n1"},[_vm._v(" "+_vm._s(_vm.school.name)+" ")]),_c(VListItem,{staticClass:"mb-5",attrs:{"to":{ name: 'school-picker' }}},[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSwapHorizontal)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("School veranderen")])],1)],1),_c(VDivider),_c(VListItem,{attrs:{"to":{ name: 'apps-user-view', params: { id: 21 } }}},[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Profiel")])],1)],1),_c('a',{staticStyle:{"text-decoration":"auto"},attrs:{"href":"mailto:jonas@blokpass.be","target":"_blank"}},[_c(VListItem,[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChatQuestionOutline)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Vraag stellen")])],1)],1)],1),_c(VDivider,{staticClass:"my-2"}),_c(VListItem,{on:{"click":_vm.logoutUser}},[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLogoutVariant)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Uitloggen")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }