import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import administration from './administration'
import importRoutes from './import'
import loginDetails from './loginDetails'
import pluginLogin from './pluginLogin'
import print from './print'
import users from './users'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (!userData) {
        console.log('redirecting to the login page')

        return { name: 'auth-login-not-logged-in' }
      }

      return { name: 'users-pupils-list' }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      role: 'guest',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      role: 'guest',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/loginTokenExpired',
    name: 'auth-login-token-expired',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      role: 'guest',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/notLoggedIn',
    name: 'auth-login-not-logged-in',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      role: 'guest',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/loggedOut',
    name: 'auth-login-logged-out',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      role: 'guest',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      role: 'guest',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/school-picker',
    name: 'school-picker',
    component: () => import('@/views/SchoolPicker.vue'),
    meta: {
      layout: 'blank',
      role: 'guest',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      role: 'guest',
      redirectIfLoggedIn: true,
    },
  },
  ...users,
  ...pluginLogin,
  ...administration,
  ...print,
  ...importRoutes,
  ...loginDetails,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')

  const isLoggedIn = userData && localStorage.getItem('accessToken')

  if (isLoggedIn) {
    // Make sure that the token hasn't expired yet
    const decodedJWT = JSON.parse(window.atob(localStorage.getItem('accessToken').split('.')[1]))
    const tokenExpiry = decodedJWT.exp * 1000

    if (tokenExpiry < Date.now()) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('school')
      localStorage.removeItem('exp')

      if (to.name.indexOf('plugin') === -1) {
        next('/loginTokenExpired')
      }
    }
  }

  if (!canNavigate(to, next)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // If logged in => not authorized
    console.log('Going to redirect because the user is not authorized to view this page')
    console.log(to)

    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/school-picker')
  }

  return next()
})

export default router
