import { mdiFormTextboxPassword } from '@mdi/js'

export default [
  {
    subheader: 'INLOGGEN',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    title: 'Inloggegevens',
    icon: mdiFormTextboxPassword,
    to: 'login-details-pupils-list',
    resource: 'LoginDetails',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder', 'Leerkracht'],
  },
]
