import { mdiFileArrowUpDownOutline } from '@mdi/js'

export default [
  {
    subheader: 'IMPORTEREN',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
  {
    title: 'Importeren',
    icon: mdiFileArrowUpDownOutline,
    to: 'import',
    action: 'read',
    allowedRoles: ['Schooladministrator', 'Globale beheerder'],
  },
]
