import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Swal from 'sweetalert2'
import Vue from 'vue'
import Skeleton from 'vue-loading-skeleton'
import VueQRCodeComponent from 'vue-qrcode-component'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.component('qr-code', VueQRCodeComponent)

Vue.prototype.$toast = function (status, title, text, duration = 3000) {
  Swal.fire({
    position: 'top-end',
    icon: status,
    title,
    text,
    showConfirmButton: false,
    timer: duration,
    toast: true,
  })
}

Vue.prototype.$swalHTML = function (status, title, html) {
  Swal.fire({
    icon: status,
    title,
    html,
  })
}

Vue.use(Skeleton)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
