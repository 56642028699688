const importRoutes = [
  {
    path: '/import-pupil',
    name: 'import-pupil',
    component: () => import('@/views/import/importPupil.vue'),
    meta: {
      layout: 'content',
      role: 'admin',
    },
  },
  {
    path: '/import-teacher',
    name: 'import-teacher',
    component: () => import('@/views/import/importTeacher.vue'),
    meta: {
      layout: 'content',
      role: 'admin',
    },
  },
]

export default importRoutes
